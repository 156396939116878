import { ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { debounceTime, distinctUntilChanged, skip, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DialogComponent, PositionDataModel } from '@syncfusion/ej2-angular-popups';
import { AppConfig, APP_CONFIG } from './app-config.module';

import { EmitType } from '@syncfusion/ej2-base';
import { ForensicNotificationServics } from './service/forensic-notification.servics';
import { ForensicUserProfileService } from './service/forensicUserProfile.service';
import DateUtils from './shared/Utility/DateUtils';
import { ToastrService } from 'ngx-toastr';
import { ProjectLevelNaming } from './shared/customObjects/ProjectLevelNaming';
import { CaseManagementIncidentNumbersComponent } from './setting/components/case-management-incident-numbers/case-management-incident-numbers.component';
import { AdminCmsSettingsModel } from './shared/customObjects/adminCmsSettingsModel';

import { MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { Observable, combineLatest, pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UserModel } from './shared/customObjects/userModel';
import { AccountSetupComponent } from './account-setup/account-setup.component';
import { SettingComponent } from './setting/setting.component';
import { AuthTokenService } from './service/auth-token.service';
import { OrganizationService } from './service/organization.service';
// import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import ErrorHandling from './shared/Utility/ErrorHandling';
import SecurityUtils from './shared/Utility/securityUtils';

import { LOCATION } from '@ng-web-apis/common';
import { Insights } from './service/insights.service';
import { DownloadService } from './service/download.service';
import { DownloadConfirmationDialogComponent } from './download/download-confirmation-dialog/download-confirmation-dialog.component';
import { IndexDBService } from './service/indexDB/index-db.service';
import { ClientEncryptionPasswordService } from './service/client-encryption-password.service';
import { UserProfileService } from './service/user-profile.service';
import { LogoutService } from './service/logout.service';
import { LazyLoadService, LazyLoadableComponents } from './service/lazy-load.service';
import { HubMessageType, HubService } from './service/hub.service';
import { SwUpdateService } from './service/sw-update.service';
import { NetworkConnectionService } from './service/network-connection.service';
import { SwMessageService } from './service/sw-message.service';
import { AuthService } from './service/auth.service';
import { DialogService, DialogType } from './service/dialog.service';
import { EncryptionPasswordComponent } from './shared/components/encryption-password/encryption-password.component';
import { SyncService } from './service/offline/sync.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;

  @ViewChild('contentDiv')
  contentDiv: any;

  @ViewChild('ejDialogheadMenu', { static: true })
  ejDialogheadMenu!: DialogComponent;

  @ViewChild('ejDialogSettings', { static: true })
  ejDialogSettings: SettingComponent;

  @ViewChild('iframeSettings', { static: true })
  iframeSettings!: HTMLBaseElement;

  @ViewChild('componentIncidents')
  componentIncidents: CaseManagementIncidentNumbersComponent;

  @ViewChild('ejDialogNotification', { static: true })
  ejDialogNotification!: DialogComponent;

  @ViewChild('ejDialogConfirmDownload')
  ejDialogConfirmDownload: DownloadConfirmationDialogComponent;

  @ViewChild('componentAccountSetup')
  componentAccountSetup: AccountSetupComponent;

  @ViewChild('dynamicDialogComponents', { read: ViewContainerRef })
  dynamicDialogComponents: ViewContainerRef;

  @ViewChild('componentEncrytionPassword')
  componentEncrytionPassword: EncryptionPasswordComponent;
  // ============================================================================
  // User InActivity Check
  // If InActive - Display Dialog with button to Reactivate
  // This will allow us to ensure SignalR is properly running
  // AND Lock App Requring a PIN (future)
  // ============================================================================
  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:keydown', ['$event'])
  @HostListener('document:touchstart', ['$event'])
  handleUserActivity() {
    this.resetInactivityTimer();
  }

  @HostListener('window:resize', ['$event'])
  onResize(even: any) {
    this.screenSizeSetup();
  }

  // ------------------------------------------------------------------
  // class name of Div tag where Dialogs can be dragged around in.
  // Found in the app.component.html file.
  // ------------------------------------------------------------------
  readonly allowDialogDragging: Boolean = true;
  readonly dialogDragTarget = '.dialogDrag-section';
  readonly topPosition: PositionDataModel = { X: 'center', Y: 'top' };
  readonly animationSettings: Object = {
    effect: 'Zoom',
    duration: 600,
  };

  // -------------------------------------------------------------------------
  // App Inactivity Tracker
  // We want to be able to lock the app after X Minutes of Inactivity
  // -------------------------------------------------------------------------
  private inactivityTimer: any;
  private readonly inactivityDuration = 20 * 60 * 1000; // 5 minutes of inactivity ==  5 * 60 * 1000;
  private downloadRelatedNodeID: string = '';

  public onOverlayClickheadMenu: EmitType<object> = () => {
    // this.isActiveMenu = false;
    this.ejDialogheadMenu.hide();
  };

  public onOverlayClickNotification: EmitType<object> = () => {
    this.ejDialogNotification.hide();
  };

  public showTeamNotebooksMenu: boolean = false;
  public nCount: number = 0;
  public fullcount: number = 20;
  public allNotification: any[] = [];
  public allUnreadNotification: any[] = [];
  public allUnreadAndreadNotification: any[] = [];
  public p = 1;
  public z: any[] = [];
  public index!: number;
  public interval: any;
  public SetInterval: any;
  public StopInterval: any;
  public AdvanceMode: string = this.config.AdvanceMode;
  public showCMS: boolean = this.config.showCMS;
  public FAQ: string = this.config.FAQ;
  public Preload = false;
  public unReadNotificationIDs: any[] = [];
  public personobject: any;
  public isActiveMenu: boolean = false;
  public user: UserModel = new UserModel();
  public screenWidth: any = 1920;
  public screenHeight: any = 1080;
  public iFrameWidth: any = 1600;
  public iFrameHeight: any = 500;

  public isTheSameTimeZoneOffset: boolean = true;

  public isLoading: boolean = false; // NOTE: Hides certain elements on page till data obtained from API

  public curentVersion$: Observable<string> = this.swUpdateService.version$;
  public isOnline$ = this.networkConnectionService.networkStatus$;

  public notShowLayout$!: Observable<boolean>;
  private isCallMasterAPI = false;
  private checkUserCount = 0;
  constructor(
    private authService: AuthService, //init AuthService
    private syncService: SyncService, //init SyncService
    private swUpdateService: SwUpdateService,
    private swMessageService: SwMessageService,
    private observer: BreakpointObserver,
    private router: Router,
    @Inject(APP_CONFIG) private config: AppConfig,
    private _notificationService: ForensicNotificationServics,
    private forensicUserProfileService: ForensicUserProfileService,
    public toastr: ToastrService,
    public customProjectNaming: ProjectLevelNaming,
    public adminCmsSettingsModel: AdminCmsSettingsModel,
    // MSAL
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    public broadcastService: MsalBroadcastService,
    public authTokenService: AuthTokenService,
    private organizationService: OrganizationService,
    public errorHandling: ErrorHandling,
    public securityUtils: SecurityUtils,
    private insights: Insights,
    private downloadService: DownloadService,

    @Inject(LOCATION) readonly location: Location,
    private indexDBService: IndexDBService,
    private hubService: HubService,
    private clientEncryptionPasswordService: ClientEncryptionPasswordService,
    private userProfileService: UserProfileService,
    private logoutService: LogoutService,
    private lazyLoadService: LazyLoadService,
    private networkConnectionService: NetworkConnectionService,
    private activatedRoute: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private dialogService: DialogService
  ) {
    // Constructor

    this.insights.trackTrace('APP STARTED');

    this.resetInactivityTimer();
    this.notShowLayout$ = router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => route.firstChild),
      switchMap((route) => route.data),
      map((data) => !!data['notShowLayout']),
      debounceTime(100)
    );
  }

  ngOnInit() {
    console.log('ngOnInit - app component3');
    this.dialogService.open$.subscribe(({ type, data }) => {
      this.openDialog(type, data);
    });

    console.log('securityUtils', this.securityUtils.isServiceWorkerCacheEnabled());

    // -------------------------------------------
    // Make sure all dynamic forms show properly
    // -------------------------------------------
    this.screenSizeSetup();

    this.setupHubListener();
    this.setupSideMenu();
  }

  ngAfterViewInit() {
    console.log('ngAfterViewInit');
    this.CheckProfileAndSetup(false);

    // ------------------------------------------------------------------------------
    // HIDE Loading Message after X seconds (the message shown while app loads)
    // TODO - TEST WHY I HAVE IT HIDDEN AGAIN BELOW
    // ------------------------------------------------------------------------------
    setTimeout(() => {
      document.getElementById('loadingMsg')!.style.display = 'none';
    }, 4000);

    // -----------------------------------------------------------------
    // Make sure the Loading Message from index.html is now hidden
    // -----------------------------------------------------------------
    document.getElementById('loadingMsg')!.style.display = 'none';

    this.networkConnectionService.networkStatus$.pipe(debounceTime(500)).subscribe((value) => {
      if(!value){
        this.checkCustomKEK();
      }
    })
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy');

    // ----------------------------------------------------------------
    // NOTE: Just because ngonDestroy is called does NOT mean
    // the user logged out or that we want to delete all Cache.
    // Leave Cache as-is until logout
    // ----------------------------------------------------------------

    // Clear Password
    this.clientEncryptionPasswordService.clearUserKEK();
  }

  logout() {
    this.logoutService.logout();
  }

  // ============================================================================
  // Check Profile and Setup
  // ============================================================================
  CheckProfileAndSetup(showMsgToUser: boolean) {
    // ----------------------------------------------------------------
    // Assign User from Service run at Startup (APP_INITIALIZER)
    // ----------------------------------------------------------------
    this.user = this.userProfileService.user;

    console.log('getUserProfile() --> Profile = ', this.user);

    if (!this.user.userID) {
      this.checkUserCount++;
      // ------------------------------------------------------------------------
      // User Profile NOT SETUP YET
      // Recall this method X seconds from now.
      // ------------------------------------------------------------------------
      setTimeout(() => {
        console.log('Checking to See if Profile Loaded Yet');
        this.CheckProfileAndSetup(false);
      }, 1000);
      // Return from this current call
      return;
    }
    else{
      // -----------------------------------------------
      // UserID ready... 
      // SPECIAL CASE - CMS - Show for some clients
      // -----------------------------------------------
      if(this.user.userID == '5ed78de3-57ae-4118-998f-e93084818529' || this.user.userID == 'c13bc1e1-5cb8-4721-90fc-8cf1ec09548f'){
        this.showCMS = true;
      }

    }

    // ----------------------------------------------------------------------------------
    // Conduct Checks and Show Appropriate Pages Depending on User Setup Requirements
    // ----------------------------------------------------------------------------------

    if (this.user.agreeToTerms == false || this.user.ianaTimeZoneId == '') {
      // -----------------------------------------------------
      // User has not completed the signup process...
      // Show user the Agree to Terms and TimeZone Selection
      // -----------------------------------------------------

      console.log('Setup NOT COMPLETED');

      console.log('Account Setup');

      let isTimeZoneSet: boolean = false;
      if (this.user.ianaTimeZoneId) {
        // timezone is set...
        isTimeZoneSet = true;
      }
      this.componentAccountSetup.LoadControl(this.user.agreeToTerms, isTimeZoneSet);

      // ------------------------------
      // Update Notifications
      // ------------------------------
      this.refreshnotification(this.fullcount);

      this.isLoading = false;
    } else {
      // ----------------------------
      // User Properly Setup
      // ----------------------------

      console.log('Account Setup Properly');

      this.isTheSameTimeZoneOffset = DateUtils.isLocalTimeOffSetSameAsProfileTimezoneOffset(this.user.ianaTimeZoneId);

      if (showMsgToUser) {
        if (this.isTheSameTimeZoneOffset) {
          // Match
          this.toastr.success('TimeZone Properly Set');
        } else {
          this.toastr.warning("Sorry, TimeZone's do not match.<br>Refresh the browser page (f5) if you adjusted your system Timezone.<br>If you need help, please contact support.", '', {
            enableHtml: true,
          });
        }
      }

      // --------------------
      // Show Notifications
      // --------------------
      this.refreshnotification(this.fullcount);

      console.log('Global Admin', this.user.isGroupAdmin);

      this.showTeamNotebooksMenu = this.user.isGroupAdmin;

      this.isLoading = false;
    }
    if (!this.isCallMasterAPI) {
      this.isCallMasterAPI = true;
      console.log('customProjectNaming', this.customProjectNaming);
      if (this.networkConnectionService.isOnline) {
        this.customProjectNaming.ProjectLevelNaming();
        this.loadCMSSettings();
        this.cd.detectChanges();
      } else {
        this.networkConnectionService.networkStatus$.pipe(skip(1), take(1)).subscribe((value) => {
          if (value) {
            this.customProjectNaming.ProjectLevelNaming();
            this.loadCMSSettings();
            this.cd.detectChanges();
          }
        });
      }
    }
  }

  pageChanged(args) {
    this.index = 0;
    this.p = args;
    this.index = (args - 1) * 3;
  }

  closeSideMenuIfMobile() {
    console.log('closeSideMenuIfMobile --> Width = ', this.screenWidth);
    if (this.screenWidth < 800) {
      // ------------------------------------------
      // Mobile device or small viewport
      // Hide Side Menu
      // ------------------------------------------
      this.sidenav.toggle();
    }
  }

  showMenu() {
    console.log('Show menu');
    this.isActiveMenu = !this.isActiveMenu;
    this.ejDialogheadMenu.show();
  }

  onShowNotification(event: any) {
    this.ejDialogNotification.show();
  }

  viewAllNotification() {
    document.getElementById!('Allnotification')!.style.display = 'block';
    document.getElementById('ViewAllNotification')!.style.display = 'none';
  }

  goSetting(href: string) {
    this.ejDialogSettings.show();
    this.ejDialogSettings.LoadControl(this.user);

    this.ejDialogheadMenu.hide();
  }

  unreadNotification() {
    if (this.nCount === 0) {
      document.getElementById('Allnotification')!.style.display = 'none';
      document.getElementById('ViewAllNotification')!.style.display = 'block';
    } else {
      this.allNotification = this.allUnreadNotification;
    }
  }

  readAllNotification() {
    for (let i = 0; i < this.unReadNotificationIDs.length; i++) {
      this._notificationService.MarkAsReadNotification(this.unReadNotificationIDs[i]).subscribe((r) => {
        if (r.success == true) {
          this.refreshnotification(this.fullcount);
        }
      });
    }
  }

  markAsReadOrUnread(notification) {
    console.log('MarkAsReadOrUnread-notification', notification);

    // ----------------------------------------------------
    // Notebook PDF or ZIP Download?
    // ----------------------------------------------------
    if (notification.categoryID == 20 || notification.categoryID == 21) {
      this.downloadRelatedNodeID = notification.relatedNodeID;

      console.log('relatedNodeID', this.downloadRelatedNodeID);

      // --------------------------------------------
      // Open Download Dialog Confirmation
      // --------------------------------------------
      this.ejDialogConfirmDownload.LoadControlFromNotification(this.downloadRelatedNodeID, notification.categoryID, notification.notificationMessage, notification.dateNotificationCreated);

      // Hide Notifications
      this.ejDialogNotification.hide();

      // Show Download Confirmation Dialog
      this.ejDialogConfirmDownload.show();
    }

    // -------------------------------------
    // Enable/Disable (Unread/Read)
    // -------------------------------------
    var getDiv = document.getElementById('ntf_' + notification.notificationID);

    // if (getDiv!.style.opacity == '0.5') {

    if (notification.dateTimeNotificationRead) {
      // Once Read - User can't mark as UnRead - Read Timestamp Altered
      // // --------------------------
      // // Mark as UnRead
      // // --------------------------
      // console.log("Mark as Unread");
      // this._notificationService.MarkAsUnReadNotification(notification.notificationID).subscribe((r) => {
      //   if (r.success == true) {
      //     this.refreshnotification(this.fullcount);
      //   }
      // });
    } else {
      // --------------------------
      // Mark as Read
      // --------------------------
      console.log('Mark as Read');

      this._notificationService.MarkAsReadNotification(notification.notificationID).subscribe((r) => {
        if (r.success == true) {
          this.refreshnotification(this.fullcount);
        }
      });
    }
  }

  refreshnotification(numberToDisplay: number) {
    console.log('refreshnotification', numberToDisplay);

    document.getElementById('Refresh')!.classList.add('fa-spin');

    this._notificationService.GetAllNotification(numberToDisplay, this.securityUtils.isServiceWorkerCacheEnabled(), this.user.userID).subscribe((r) => {
      console.log('GetAllNotification-Results', r.result);

      this.allNotification = r.result;

      console.log('User', this.user);
      let userTimeZone = this.user.ianaTimeZoneId;
      let dateNotificationCreatedStr: string = '';
      let dateTimeNotificationReadStr: string = '';

      console.log('Timezone', userTimeZone);

      this.allNotification.forEach(function (e) {
        if (typeof e === 'object') {
          console.log('e - Created', new Date(e.dateNotificationCreated).getTime());

          let tmpDate: Date = DateUtils.ConverUTCtDateToUsersTimeZoneFromAngularTicksForDateTimeDisplayControl(new Date(e.dateNotificationCreated).getTime(), userTimeZone);
          console.log('tmpDate', tmpDate);
          e.dateNotificationCreatedStr = DateUtils.FormatDateTimeStringNoSeconds(tmpDate);
          console.log('e - Created-AFTER', e.dateNotificationCreatedStr);

          e.dateTimeNotificationReadStr = DateUtils.FormatDateTimeStringNoSeconds(
            DateUtils.ConverUTCtDateToUsersTimeZoneFromAngularTicksForDateTimeDisplayControl(new Date(e.dateTimeNotificationRead).getTime(), userTimeZone)
          );
          // console.log("e - READ-AFTER", e.dateTimeNotificationReadStr);
        }
      });

      console.log('allNotification', this.allNotification);

      this.fullcount = r.fullCount;
      this.nCount = 0;
      this.allUnreadNotification = [];
      this.unReadNotificationIDs = [];
      for (var i = 0; i < r.result.length; i++) {
        if (r.result[i].dateTimeNotificationRead == undefined) {
          this.nCount++;
          this.unReadNotificationIDs.push(r.result[i].notificationID);
          this.allUnreadNotification.push(r.result[i]);
        }
      }
      console.log('returned');
      document.getElementById('Refresh')!.classList.remove('fa-spin');
    });
  }

  updateApp() {
    window.location.reload();
  }

  private async resetInactivityTimer() {
    if(!this.networkConnectionService.isOnline) {
      return;
    }
    clearTimeout(this.inactivityTimer);
    this.inactivityTimer = setTimeout(async () => {
      if(!this.networkConnectionService.isOnline) {
        return;
      }
      // ------------------------------------------
      // User Inactive
      // Show Inactivity Dialog
      // ------------------------------------------
      console.log('User inactive for a specified duration.');

      //

      const componentInstance = await this.lazyLoadService.getLazyLoadComponentInstance(LazyLoadableComponents.LockScreenComponent, this.dynamicDialogComponents);

      componentInstance.show();

      // -----------------------------------------------------
      // Capture Events from the Child by Subscribing
      // Make sure unsubscribe in ngOnDestroy()
      //
      // NOTE: .pipe(take(1)) ensures that the subscription
      // is only run 1 time which is required as this
      // component can be called and created numerous times!
      // -----------------------------------------------------
      componentInstance['logoutUserEvent'].pipe(take(1)).subscribe((EventEmitter) => {
        this.logout();
      });

      componentInstance['reactivateAppEvent'].pipe(take(1)).subscribe((EventEmitter) => {
        this.hubService.reactiveConnection().then(() => {
          console.log('SignalR Reactivated');
        });
      });
    }, this.inactivityDuration);
  }

  private SignalRNotebookDownloadReady(generationID: string, notebookID: string, fileType: string, fileSize: number) {
    console.log('SignalRNotebookDownloadReady generationID = ' + generationID);
    console.log('SignalRNotebookDownloadReady notebookId = ' + notebookID);
    console.log('SignalRNotebookDownloadReady FileType = ' + fileType);
    console.log('SignalRNotebookDownloadReady filesize = ' + fileSize);

    this.ejDialogConfirmDownload.LoadControlFromSignalR(generationID, notebookID, fileType, fileSize);
    this.ejDialogConfirmDownload.show();

    // ----------------------------------------------------------------------------------
    // Notfiication SignalR not working properly
    // Not getting detected. Need to check on Server to see if actually sending.
    // Till then, force refresh of notifications.
    // TODO - Fix this
    // ----------------------------------------------------------------------------------
    this.refreshnotification(this.fullcount);
  }

  private loadCMSSettings() {
    console.log('LoadCMSSettings()' + new Date().toTimeString());

    this.organizationService.GetAdminCmsSettings().subscribe(
      (result) => {
        console.log('LoadCMSSettings - CMS Settings' + new Date().toTimeString(), result);

        // Load Results
        this.adminCmsSettingsModel.LoadSettingsFromResult(result);

        // this.errorHandling.LogErrorToServer("testExc", "stackTrace", this.user);
      },
      (error) => {
        let msg: string = 'Unknown Error Getting CMS Settings - Please contact support if you continue to experience this problem.';
        this.toastr.error(msg);
        this.errorHandling.LogErrorToServer(msg, error);
        console.error(error);
      }
    );
  }

  private setupSideMenu() {
    console.log('Setting up control');

    // ------------------------------------------------
    // Adjust Side Menu based on size of browser
    // ------------------------------------------------
    const checkSidenav$ = this.observer.observe(['(max-width: 800px)']).pipe(
      map((e) => e.matches),
      distinctUntilChanged()
    );
    combineLatest({
      matches: checkSidenav$,
      notShowLayout: this.notShowLayout$.pipe(distinctUntilChanged()),
    })
      .pipe(debounceTime(200))
      .subscribe(({ matches, notShowLayout }) => {
        this.sidenav.mode = matches ? 'over' : 'side';
        if (notShowLayout) {
          return;
        }
        if (matches) {
          this.sidenav.close();
        } else {
          this.sidenav.open();
        }
        this.cd.detectChanges();
      });
    this.observer
      .observe(['(max-width: 800px)'])
      .pipe(debounceTime(200), withLatestFrom(this.notShowLayout$))
      .subscribe(([res, notShowLayout]) => {
        const { matches } = res;
        this.sidenav.mode = matches ? 'over' : 'side';
        if (notShowLayout) {
          return;
        }
      });
  }

  private screenSizeSetup() {
    // -------------------------------------------
    // Initial Page Load - This is = 0
    // so use default values in that case
    // -------------------------------------------
    if (window.innerWidth != 0) {
      //console.log("Setting Width and height from window");
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
    }

    console.log('================================================');
    console.log('=========== DIALOG SIZE SETUP ==================');
    console.log('================================================');

    console.log('Width (resized):', this.screenWidth);
    console.log('Height (resized):', this.screenHeight);

    // WIDTH
    let MAX_WIDTH: number = 1600;

    let width: number = MAX_WIDTH; // MAX width needed
    if (this.screenWidth < MAX_WIDTH) {
      width = this.screenWidth;
    }

    // HEIGHTf
    let height: number = this.screenHeight;

    // --------------------------------------------------------------------
    // Desktop - Bigger Screens, provide more padding around popup dialog
    // But on MOBILE, we need ALL Space.
    // --------------------------------------------------------------------

    let padding: number = 50;

    if (height < 1000 && width < 1000) {
      // Small Screen, so less padding.
      padding = 2;
    }

    //console.logObj("Padding = " + padding);

    // ----------------
    // FINAL Width
    // ----------------
    width = width - padding;
    height = height - padding;

    // this.ejDialogSettings.width = width;
    // this.ejDialogSettings.height = height;

    this.iFrameWidth = width - 20;
    this.iFrameHeight = height - 90; // Adjust to remove scrollbar and show padding at bottom of iframe
  }

  private setupHubListener() {
    this.hubService.hub$.pipe(filter(({ type }) => [HubMessageType.NotificationRecieved, HubMessageType.NotebookGenerated].includes(type))).subscribe(({ type, content }) => {
      if (type === HubMessageType.NotificationRecieved) {
        console.error('NOTIFICATION');
      }
      if (type === HubMessageType.NotebookGenerated) {
        const { generationID, notebookId, fileType, filesize } = content;
        this.SignalRNotebookDownloadReady(generationID, notebookId, fileType, filesize);
      }
    });
  }

  private openDialog(type: DialogType, data: any) {
    switch (type) {
      case DialogType.EncryptionPasswordComponent:
        this.componentEncrytionPassword.show();
        break;

      default:
        break;
    }
  }

  private checkCustomKEK() {
    const KEK = this.clientEncryptionPasswordService.getPassword();
    const isUseCustomKEK = this.clientEncryptionPasswordService.checkUseCustomKEK();
    if (isUseCustomKEK && !KEK) {
      this.componentEncrytionPassword.show();
      return;
    }
    return;
  }
}
